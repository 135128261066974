@font-face {
  font-family: perandory;
  src: url(../public/PERANDORY/Perandory-Regular.otf);
  font-weight: bold;
}

.App {
  text-align: center;
}

.background-gradient {
  background-image: 
  radial-gradient(at 6% 59%, hsla(216,68%,8%,0.53) 0px, transparent 50%),
  radial-gradient(at 85% 19%, hsla(240,62%,22%,0.5) 0px, transparent 50%),
  radial-gradient(at 79% 59%, hsla(216,65%,5%,0.39) 0px, transparent 50%),
  radial-gradient(at 76% 89%, hsla(255,43%,54%,0.57) 0px, transparent 50%),
  radial-gradient(at 11% 24%, hsla(195,46%,64%,0.52) 0px, transparent 50%),
  radial-gradient(at 7% 75%, hsla(234,100%,65%,1) 0px, transparent 50%),
  radial-gradient(at 59% 15%, hsla(234,56%,15%,1) 0px, transparent 50%);
  background-color:hsla(216,56%,6%,1);
  background-attachment: fixed;
  position: absolute;
}

.App-body {
  
 background-color:hsla(216,56%,6%,1);
  
  
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  font-family: "Futura", sans-serif;
  padding: 0;
  text-align: center;
  overflow: hidden;
  z-index: 2;
}

h1 {
  font-family: "perandory", serif;
  font-size: 70px;
  margin-bottom: 30px;
  z-index: 1;
  text-align: center;
}



h2 {
  font-family: "perandory", serif;
  font-size: 50px;
  margin-bottom: 30px;
  z-index: 1;
}

h3 {
  font-family: "Futura", sans-serif;
  font-weight: normal;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
}

img {
  pointer-events: none; /* Prevents the image from being clicked, hovered over, or dragged */
  user-select: none; /* Prevents the image from being selected by the user */
  -webkit-user-drag: none; /* Prevents the image from being dragged in WebKit browsers */
}

button {
  z-index: 2;
}

.app-footer {
  background-color: white;
  z-index: 4;
  width: 100%;
  padding: 1px;
  text-align: center;
  max-height: 100px;
}
.background-stars {
  background-color: #090428;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: absolute;
  font-family: "Futura", sans-serif;
  padding: 0;
  z-index: 1;
}

.homePageText {
  z-index: 2;
  position: absolute;
  padding: 20px;
}

.continuebutton {
  z-index: 2;
  padding: 10px;
}

.gradient-background {
  opacity: .6;
  width: 100vh;
  max-height: 100%;
}

.background {
  position: absolute;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

}

#noise {
  object-fit: cover;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  opacity: .2;
}


#circle1 {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
}

#circle2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}
#circle3 {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 0;
}
#circle4 {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 0;
}



#Login-button {
  padding: 10px 15px 10px 15px;
  background: transparent;
  color: white;
  border-radius: 40px;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  font-family: "Futura", sans-serif;
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 80px;
  z-index: 2;
}


#Login-button:hover {
  cursor: pointer; /* Change cursor to pointer on hover */
}

#scorpiosvg {
  position: absolute;
  padding-right: 500px;
  padding-bottom: 400px;
  width: 200px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}


#ariessvg {
  position: absolute;
  padding-left: 900px;
  padding-bottom: 600px;
  width: 200px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#sagsvg {
  position: absolute;
  padding-right: 900px;
  padding-top: 600px;
  width: 200px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#taursvg {
  position: absolute;
  padding-left: 500px;
  padding-top: 400px;
  width: 200px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#starsvg1 {
  position: absolute;
  padding-left: 100px;
  padding-top: -300px;
  width: 10px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}
#starsvg2 {
  position: absolute;
  padding-right: 600px;
  padding-top: 300px;
  width: 8px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}
#starsvg3 {
  position: absolute;
  padding-left: 200px;
  padding-top: 500px;
  width: 10px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}
#starsvg4 {
  position: absolute;
  padding-left: 750px;
  padding-top: -500px;
  width: 10px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}
#starsvg5 {
  position: absolute;
  padding-left: 900px;
  padding-top: 600px;
  width: 9px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}
#starsvg6 {
  position: absolute;
  padding-right: 900px;
  padding-bottom: 500px;
  width: 11px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}
#starsvg7 {
  position: absolute;
  padding-left: 1100px;
  padding-top: 200px;
  width: 8px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}
#starsvg8 {
  position: absolute;
  padding-right: 1200px;
  padding-bottom: 100px;
  width: 7px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#starsvg9 {
  position: absolute;
  padding-right: 1100px;
  padding-bottom: 600px;
  width: 9px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#starsvg10 {
  position: absolute;
  padding-left: 1000px;
  padding-bottom: 700px;
  width: 10px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#starsvg11 {
  position: absolute;
  padding-left: 1200px;
  padding-bottom: 300px;
  width: 10px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#starsvg12 {
  position: absolute;
  padding-right: 1300px;
  padding-bottom: 400px;
  width: 10px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#starsvg13 {
  position: absolute;
  padding-left: 1500px;
  padding-top: 600px;
  width: 10px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}
#starsvg14 {
  position: absolute;
  padding-right: 1500px;
  padding-top: 400px;
  width: 10px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#results-left-constellation {
  position: absolute;
  padding-right: 900px;
  padding-bottom: 200px;
  width: 200px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
}

#results-constellation {
  width: 200px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
  margin-top: 20px;
}

#results-right-constellation {
  position: absolute;
  padding-right: 900px;
  padding-bottom: 200px;
  width: 200px; /* Adjust the width of the SVG image */
  height: auto; /* Maintain aspect ratio */
  transform: rotate(180deg);
}

.Results-image {
  padding: 1%;
  margin: 20px;
  width: 18em;
  height: 32em;

}

.Results-image ul li {
  font-size: 1em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.zodiac-buttons {
  margin: 0 10% 80px 10%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
  justify-content: center;
}

.zodiac-buttons button:hover {
  cursor: pointer;
}

.zodiac-buttons button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid;
  border-color: rgb(255 255 255 / 40%);
  background-color: transparent;
  color: white;
  border-radius: 5px;
  padding: 10px 15px 1px 15px;
  font-family: "Futura", sans-serif;
  height: 180px;
  width: 140px;
  backdrop-filter: blur(2px);
}

.zodiac-buttons img {
  height: 70px;
  width: 100px;
  margin-bottom: -10px;
  margin-top: 10px;
}

.zodiac-buttons h5 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: -5px;
}

.zodiac-buttons .selected{
  border-color: white;
  border-width: 3px;
  padding: 9px 14px 0px 14px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
}

.zodiac-buttons .selected h5{
  font-weight: bold;
}

.zodiac-buttons p{
  font-size: 1em;
}

#playlist-button {
  padding: 10px 15px 10px 15px;
  background: transparent;
  color: white;
  border-radius: 10px;
  border-color: white;
  border-style: solid;
  font-family: "Futura", sans-serif;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 80px;
  z-index: 2;
}

#playlist-button a {
  z-index: 3;
  color: white;
  text-decoration: none;
  font-family: "Futura", sans-serif;
}



#recommendedsongs {
  text-align: center;
  list-style: none;
  z-index: 3;
  text-decoration: none;
  color: white;
  font-weight: normal;
}



.track-name a:link {
  font-weight: normal;
  color: white;
  text-decoration: none;
  font-size: 1em;
}

.track-name a:visited {
  font-weight: normal;
  color: white;
  text-decoration: none;
}

.artist a:link {
  color: white;
  text-decoration: none;
  font-size: 0.7em;
}

.artist a:visited {
  color: white;
  text-decoration: none;
}

li {
  margin-bottom: 20px;
  text-align: center;
  padding-right: 25px;
}

#results-header {
  margin-bottom: -20px;
}

#more {
  
  text-align: center;
  background: transparent;
  color: white;
  font-family: "Futura", sans-serif;
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 80px;
  border: none;
  
}

#more:hover {
  cursor: pointer; /* Change cursor to pointer on hover */
}


.fadeIn {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.spotifylogo {
  width: 90px;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  #taursvg {
    position: absolute;
    padding-left: 0px;
    padding-top: 500px;
    width: 200px; /* Adjust the width of the SVG image */
    height: auto; /* Maintain aspect ratio */
  }

  #scorpiosvg {
    position: absolute;
    padding-right: 0px;
    padding-bottom: 600px;
    width: 200px; /* Adjust the width of the SVG image */
    height: auto; /* Maintain aspect ratio */
  }

  #starsvg1 {
    position: absolute;
    padding-right: 300px;
    padding-top: 400px;
    width: 10px; /* Adjust the width of the SVG image */
    height: auto; /* Maintain aspect ratio */
  }
  #starsvg2 {
    position: absolute;
    padding-left: 900px;
    padding-bottom: 700px;
    width: 8px; /* Adjust the width of the SVG image */
    height: auto; /* Maintain aspect ratio */
  }
  #starsvg3 {
    position: absolute;
    padding-right: 500px;
    padding-bottom: 1300px;
    width: 10px; /* Adjust the width of the SVG image */
    height: auto; /* Maintain aspect ratio */
  }

}

/* ---- stats.js ---- */

.count-particles{
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13E8E9;
  font-size: .8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.js-count-particles{
  font-size: 1.1em;
}

.About-footer {
  width: 95%;
  display: flex;
  justify-content: space-between;
  z-index: 5;
  font-size: 12px;
  position: absolute;
  bottom: 0;
}

.About-footer a {
  text-decoration: none;
  color: white;
}

.About-footer a:hover {
  text-decoration: underline;
  color: white;
}

canvas {
  border: 1px solid black;
}

h5 {
  font-weight: normal;
}